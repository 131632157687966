import request from '@/utils/request'

export function create(params) {
  return request({
    url: '/answers',
    method: 'post',
    data: params,
  })
}

export function list(params) {
  return request({
    url: '/answers',
    method: 'get',
    params,
  })
}

export function show(params) {
  return request({
    url: `/answers/${params.id}`,
    method: 'get',
    params,
  })
}

export function del(id) {
  return request({
    url: `/answers/${id}`,
    method: 'delete',
  })
}

export function report(id) {
  return request({
    url: `/answers/report/${id}`,
    method: 'post',
  })
}

export function merge(params) {
  return request({
    url: '/answers/merge',
    method: 'post',
    data: params,
  })
}
