<template>
  <div class="container">
    <nav-bar title="我的检查要点报告"></nav-bar>
    <md-field>
      <md-cell-item
        v-show="mode === 'list'"
        v-for="item in list"
        :key="item.id"
        :title="item.name"
        :brief="item.menu.menu_level + ' / ' + item.created_at"
        arrow
        @click="handleClick(item)"
      />
      <md-check-list v-show="mode === 'merge'" v-model="mergeIds" iconPosition="left" :options="mergeList" />
    </md-field>
    <md-action-sheet
      v-model="visible"
      title="选择操作项"
      :default-index="0"
      :invalid-index="invalidIndex"
      :options="options"
      @selected="handleSelect"
    ></md-action-sheet>
    <md-action-bar :actions="[{ text: '合并报告', onClick: () => (this.mode = 'merge') }]" v-show="mode === 'list'"></md-action-bar>
    <md-action-bar
      :actions="[
        { text: '取消', onClick: () => (this.mode = 'list') },
        { text: '合并', onClick: handleMerge },
      ]"
      v-show="mode === 'merge'"
    ></md-action-bar>
    <md-dialog title="报告名称" :closable="false" v-model="slotDialog.open" :btns="slotDialog.btns">
      <validation-observer ref="observer">
        <md-field style="background-color:#fff">
          <v-input title="报告名称" placeholder="请填写报告名称" v-model="name" clearable rules="required" required isTitleLatent></v-input>
        </md-field>
      </validation-observer>
    </md-dialog>
  </div>
</template>

<script>
import { list, del, report, merge } from '@/api/answer'

export default {
  data() {
    return {
      list: [],
      visible: false,
      options: [],
      id: undefined,
      isPro: this.$store.getters['user/isPro'],
      item: {},
      invalidIndex: undefined,
      mode: 'list',
      mergeIds: [],
      mergeList: [],
      name: '',
      slotDialog: {
        open: false,
        btns: [
          {
            text: '提交',
            handler: this.handleSumbit,
          },
        ],
      },
    }
  },
  created() {
    if (this.isPro) {
      this.options = [
        {
          label: '查看',
          value: 0,
        },
        {
          label: '上传总队',
          value: 1,
          disabled: true,
        },
        {
          label: '删除',
          value: 2,
        },
      ]
    } else {
      this.options = [
        {
          label: '查看',
          value: 0,
        },
        {
          label: '删除',
          value: 2,
        },
      ]
    }
    list().then(res => {
      this.list = res.data
      this.mergeList = res.data.map(item => ({
        value: item.id,
        label: item.name,
        brief: item.menu.menu_level + ' / ' + item.created_at,
        disabled: item.is_merge,
      }))
    })
  },
  methods: {
    handleClick(val) {
      this.visible = true
      this.id = val.id
      this.item = val
      if (this.item.is_report) {
        this.invalidIndex = 1
      } else {
        this.invalidIndex = undefined
      }
    },
    handleSelect(val) {
      if (val.value === 0) {
        this.$router.push({ name: 'result', params: { menuId: this.item.menu.id, menuName: this.item.menu.name, answerId: this.id } })
      } else if (val.value === 2) {
        this.$dialog.confirm({
          title: '确认',
          content: '确认删除该报告吗？',
          confirmText: '确定',
          onConfirm: () =>
            del(this.id).then(() => {
              this.list = this.list.filter(item => item.id !== this.id)
              this.$toast.info('删除成功')
            }),
        })
      } else {
        this.$dialog.confirm({
          title: '确认',
          content: '确认上传该报告吗？',
          confirmText: '确定',
          onConfirm: () => report(this.id).then(() => this.$toast.info('上传成功')),
        })
      }
    },
    handleMerge() {
      if (this.mergeIds.length === 0) {
        this.$toast.info('请选择需要合并的项')
        return
      }
      this.slotDialog.open = true
    },
    handleSumbit() {
      this.$refs.observer.validate().then(result => {
        if (!result) {
          return
        }

        const params = {
          name: this.name,
          merge_ids: this.mergeIds,
        }

        merge(params).then(() => {
          this.slotDialog.open = false
          this.$toast.info('合并成功')
          list().then(res => {
            this.list = res.data
            this.mergeList = res.data.map(item => ({
              value: item.id,
              label: item.name,
              brief: item.menu.menu_level + ' / ' + item.created_at,
              disabled: item.is_merge,
            }))
          })
        })
      })
    },
  },
}
</script>
<style lang="stylus" scoped>
.container
  padding-bottom 100px
  >>>.md-button.plain
    color #2f86f6
    border 1px solid #2f86f6
</style>
